<template>
    <div class="flex-grow flex">
        <div class="flex-grow flex">
            <div class="w-full p-8 flex h-full bg-white justify-center items-center shadow-inner lg:w-1/2 xl:w-1/3">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    class="w-full flex justify-center">
                    <form
                        class="md:w-3/4"
                        @submit.prevent="handleSubmit(authenticate)">
                        <div class="text-center flex flex-wrap justify-center">
                            <span
                                class="logo rounded-lg bg-indigo-800 text-white-100 h-16 w-16 flex items-center justify-center leading-none pt-1 text-white text-4xl">M</span>
                            <h1 class="my-4 w-full">
                                Velkommen til Missile
                            </h1>
                        </div>

                        <!--<p class="pt-2 pb-6">
                            Mangler du en konto?
                            <router-link to="/signup">Lav en konto</router-link>
                        </p>-->
                        <div class="form-group">
                            <VInput
                                id="username"
                                v-model="model.userName"
                                name="Brugernavn"
                                placeholder="example@missile.dk"
                                label="Brugernavn"
                                rule="required"/>
                        </div>

                        <div class="form-group">
                            <VInput
                                id="password"
                                v-model="model.password"
                                type="password"
                                placeholder="********"
                                label="Adgangskode"
                                name="Adgangskode"
                                rule="required"/>
                        </div>
                        
                        <div v-if="errorMessages && errorMessages.length > 0">
                            <div
                                v-for="error in errorMessages"
                                :key="error"
                                class="my-2 px-3 py-2 rounded bg-red-100 text-red-900 text-sm">
                                {{ error }}
                            </div>
                        </div>

                        <div class="form-group text-right">
                            <button
                                type="submit"
                                class="btn --primary ml-4">
                                Log ind
                                <Spinner
                                    v-if="loading"
                                    color="white"
                                    class="pl-2"/>
                            </button>
                        </div>
                    </form>
                </validation-observer>
            </div>
            <div class="hidden lg:w-1/2 xl:w-2/3 hero p-6 lg:block">
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import authenticationService from '@/core/services/account/authentication.service';
import router from '@/router/router';
import teamsService from '@/core/services/teams/teams.service';
import taskTypesService from '@/core/services/tasktypes/taskTypes.service';
import Spinner from '@/components/Spinner.vue';
import { AuthenticateRequest } from '@/_typedef/missile';

const loading = ref(false);
const errorMessages = ref<string[] | null>();
const model = ref<AuthenticateRequest>({ userName: '', password: '' });

async function authenticate() {
    loading.value = true;

    try {
        await authenticationService.authenticate(model.value);

        await teamsService.getAll();
        await taskTypesService.getAll();

        loading.value = false;
        router.push('/');
    } catch (errors: any) {
        loading.value = false;
        errorMessages.value = errors;
    }
}
</script>