<template>
    <div
        class="lds-ring"
        :class="[size, color]">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Spinner extends Vue {
    @Prop({ default: 'small' }) size: 'small' | 'medium' | 'large';
    @Prop({ default: 'black' }) color: 'black' | 'indigo' | 'white';
}
</script>

<style lang="scss" scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    &.small {
        width: 14px;
        height: 14px;
    }

    &.medium {
        width: 30px;
        height: 30px;
    }

    &.white {
        div {
            border-color: white transparent transparent transparent;
        }
    }

    &.indigo {
        div {
            border-color: #434190 transparent transparent transparent;
        }
    }
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
}

.lds-ring.small div {
    width: 14px;
    height: 14px;
    margin: 0;
    border-width: 2px;
}

.lds-ring.medium div {
    width: 30px;
    height: 30px;
    margin: 0;
    border-width: 4px;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>